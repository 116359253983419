<template>
  <div class="vx-row p-1 pt-3 pb-3">
    <div
      class="vx-col md:w-1/6 text-center orderId"
      @click="OpenOrderDetail(order)"
    >
      #{{ order.id }}
    </div>
    <div class="vx-col md:w-1/3 text-center">
      {{ GetRelativeTime(order.created) }}
    </div>
    <div class="vx-col md:w-1/3 text-center">{{ order.total_weight }}</div>
    <div class="vx-col md:w-1/6 text-center">
      <div
        class="mx-auto"
        style="width: 16px !important; height: 16px; border-radius: 50%"
        :style="'background-color: ' + GetStatusColor(order.order_status)"
      ></div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  methods: {
    GetRelativeTime(value) {
      return moment(String(value)).format("DD-MM-YY - HH:mm");
    },
    OpenOrderDetail(order) {
      this.$emit("OpenOrderDetail", order);
    },
    GetStatusColor(status) {
      if (status == "P") return "#FF9F43";
      if (status == "R") return "#28C76F";
      if (status == "C") return "#EA5455";
      if (status == "W") return "#389CA3";
      return "#C89E82";
    },
  },
};
</script>

<style scoped>
.vx-row .vx-col {
  padding: 0px;
  margin: 0px;
}
.orderId {
  color: #117fed;
  cursor: pointer;
}
</style>
