<template>
  <div class="vx-row">
    <div class="vx-col md:w-4/12">
      <div class="flex content-between flex-wrap BgWhiteBS mr-2 h-full p-4">
        <div class="pl-4 mt-1 w-full flex">
          <div
            @click="BackCustomers()"
            class="mr-1"
            style="color: #434343; font-size: 1.2rem; cursor: pointer"
          >
            Customers /
          </div>
          <span
            class="ml-1"
            style="color: #434343; font-size: 1.2rem; font-weight: 600"
          >
            {{ customerDetail.name }}</span
          >
        </div>

        <div class="pl-4 mt-3 mb-1 w-full">{{ customerDetail.country }}</div>
      </div>
    </div>
    <div class="vx-col md:w-8/12">
      <div
        class="BgWhiteBS ml-2 h-full p-4"
        style="color: #434343; font-weight: 600"
      >
        <div style="width: fit-content">
          <div class="vx-row justify-between">
            <div>Total Purchased Credit</div>
            <span class="ml-6" style="color: #157fed">
              {{ customerDetail.purschased_credit_total }}
            </span>
          </div>
          <div class="vx-row justify-between">
            <div>Total Used Credit</div>
            <span class="ml-6" style="color: #ed6e69">{{
              customerDetail.used_credit
            }}</span>
          </div>
          <div class="vx-row justify-between">
            <div>Remaning Credit</div>
            <span class="ml-6" style="color: #28c76f">
              {{ customerDetail.nokta_market_credit }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div class="vx-col md:w-1/2 mt-4">
      <div class="BgWhiteBS mr-2 h-full">
        <div class="flex justify-between items-center p-4">
          <div style="font-weight: 500; font-size: 1.2rem">Last Orders</div>
          <vs-button class="pt-2 pb-2" @click="OpenOrdersPage()">
            All Orders
          </vs-button>
        </div>

        <div style="background-color: #f8f8f8; height: 5px"></div>

        <div class="vx-row orderBar p-1 pt-3 pb-3">
          <div class="vx-col md:w-1/6 text-center">Order</div>
          <div class="vx-col md:w-1/3 text-center">Created</div>
          <div class="vx-col md:w-1/3 text-center">Total Resin / Credit</div>
          <div class="vx-col md:w-1/6 text-center">Status</div>
        </div>

        <div
          v-for="(order, index) in customerDetail.last_orders"
          :key="order.id"
          :style="
            index % 2 == 0
              ? 'background-color : #F8F8F8'
              : 'background-color : white'
          "
        >
          <order-row :order="order" @OpenOrderDetail="OpenOrderDetail" />
        </div>
      </div>
    </div>
    <div class="vx-col md:w-1/2 mt-4">
      <div class="BgWhiteBS ml-2 h-full">
        <div class="flex justify-between items-center p-4">
          <div style="font-weight: 500; font-size: 1.2rem">
            Last Purchased Credits
          </div>
          <vs-button class="pt-2 pb-2" @click="OpenCreditsPage()">
            All Credits
          </vs-button>
        </div>

        <div style="background-color: #f8f8f8; height: 5px"></div>

        <received-credit :data="customerDetail.last_purshased_credits" />
      </div>
    </div>

    <!-- <div class="vx-col md:w-1/6 mt-4">
      <div class="BgWhiteBS ml-2 h-full">
        <div class="flex justify-between items-center p-4">
          <div class="mt-2" style="font-weight: 500; font-size: 1.2rem">
            Printers
          </div>
        </div>
        <div style="background-color: #f8f8f8; height: 5px"></div>
        <div
          class="flex justify-between mt-2 pl-4 pr-4"
          v-for="printer in FAKEPRINTERS"
          :key="printer.id"
        >
          <div>
            {{ printer.machineType }}
          </div>
          <div>
            {{ printer.machineCount }}
          </div>
        </div>
      </div>
    </div>
    <div class="vx-col md:w-5/6 mt-4">
      <div class="BgWhiteBS ml-2 h-full">
        <div class="flex justify-between items-center p-4">
          <div style="font-weight: 500; font-size: 1.2rem">Last Print Logs</div>
          <vs-button class="pt-2 pb-2" to="/print_logs">
            All Print Logs
          </vs-button>
        </div>
        <div style="background-color: #f8f8f8; height: 5px"></div>

        <div class="vx-row orderBar p-1 pt-3 pb-3">
          <div class="vx-col md:w-1/12 text-center">Machine</div>
          <div class="vx-col md:w-2/12 text-center">Plate Name</div>
          <div class="vx-col md:w-2/12 text-center">Profile</div>
          <div class="vx-col md:w-2/12 text-center">Print Start</div>
          <div class="vx-col md:w-2/12 text-center">Print Time</div>
          <div class="vx-col md:w-1/12 text-center">Layers</div>
          <div class="vx-col md:w-1/12 text-center">Solid Area</div>
          <div class="vx-col md:w-1/12 text-center">Status</div>
        </div>

        <div
          class="vx-row p-1 pt-3 pb-3"
          v-for="print in FAKEPRINTLOGS"
          :key="print.id"
        >
          <div class="vx-col md:w-1/12 text-center">{{ print.machine }}</div>
          <div class="vx-col md:w-2/12 text-center">{{ print.plateName }}</div>
          <div class="vx-col md:w-2/12 text-center">{{ print.profile }}</div>
          <div class="vx-col md:w-2/12 text-center">{{ print.printStart }}</div>
          <div class="vx-col md:w-2/12 text-center">{{ print.printTime }}</div>
          <div class="vx-col md:w-1/12 text-center">{{ print.layers }}</div>
          <div class="vx-col md:w-1/12 text-center">{{ print.solidArea }}</div>

          <div class="vx-col md:w-1/12 text-center">
            <div
              class="mx-auto"
              style="width: 16px !important; height: 16px; border-radius: 50%"
              :style="'background-color: ' + GetStatusColor(print.status)"
            ></div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import Api from "@/api/api";
import API from "@/api/config";

import orderRow from "./components/DetailOrderRow.vue";
import receivedCredit from "./components/DetailPurchasedCredit.vue";

export default {
  data() {
    return {
      customerDetail: {
        name: "",
      },
      FAKEPRINTERS: [
        {
          id: 1,
          machineType: "Sega 90",
          machineCount: 2,
        },
        {
          id: 2,
          machineType: "Mega 133",
          machineCount: 1,
        },
        {
          id: 3,
          machineType: "Giga",
          machineCount: 1,
        },
      ],
      FAKEPRINTLOGS: [
        {
          id: 1,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 2,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 3,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 4,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 5,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 6,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 7,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 8,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 9,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
        {
          id: 10,
          machine: 110,
          plateName: "Plate 1",
          profile: "Profile 1",
          printStart: "22-04-01 - 11:30",
          printTime: "2h",
          layers: "10",
          solidArea: "10",
          status: "R",
        },
      ],
    };
  },
  methods: {
    GetCustomerDetail() {
      this.$vs.loading({ text: this.$t("Loading_Please_Wait") });

      Api.get(
        API.BASEURL +
          API.NOKTA_MARKET_PROVIDER_CUSTOMER +
          this.$route.params.id +
          "/",
        this.HandleGetCustomerDetail
      );
    },
    HandleGetCustomerDetail(status, data) {
      this.$vs.loading.close();

      if (status == 200) {
        this.customerDetail = data;
      }
    },
    BackCustomers() {
      this.$router.push("/nokta_admin_customer").catch((error) => {
        if (error.name != "NavigationDuplicated") {
          throw error;
        }
      });
    },
    OpenOrderDetail(order) {
      this.$emit("OpenOrderDetail", order);
    },
    OpenOrdersPage() {
      this.$router
        .push({
          path: "/nokta_admin_customer/" + this.$route.params.id + "/orders",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    OpenCreditsPage() {
      this.$router
        .push({
          path: "/nokta_admin_customer/" + this.$route.params.id + "/credits",
        })
        .catch((error) => {
          if (error.name != "NavigationDuplicated") {
            throw error;
          }
        });
    },
    GetStatusColor(status) {
      if (status == "P") return "#FF9F43";
      if (status == "R") return "#28C76F";
      if (status == "C") return "#EA5455";
      if (status == "W") return "#389CA3";
      return "#C89E82";
    },
  },
  components: {
    orderRow,
    receivedCredit,
  },
  created() {
    this.GetCustomerDetail();
  },
};
</script>

<style>
.orderBar {
  font-weight: 600;
  color: #626262;
}
</style>

<style scoped>
.BgWhiteBS {
  background-color: white;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.creditBar {
  cursor: pointer;
}

.vx-row {
  padding: 0px;
  margin: 0px;
}
.vx-col {
  padding: 0px;
  margin: 0px;
}
</style>
