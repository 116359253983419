<template>
  <div>
    <div class="vx-row orderBar p-1 pt-3 pb-3">
      <div class="vx-col md:w-1/6 text-center">Credit</div>
      <div class="vx-col md:w-1/3 text-center">Payment Type</div>
      <div class="vx-col md:w-1/3 text-center">Date-Time</div>
      <div class="vx-col md:w-1/6 text-center">Status</div>
    </div>

    <div
      class="vx-row p-1 pt-3 pb-3"
      :style="
        index % 2 == 0
          ? 'background-color : #F8F8F8'
          : 'background-color : white'
      "
      v-for="(credit, index) in data"
      :key="credit.id"
    >
      <div class="vx-col md:w-1/6 text-center orderId">
        {{ credit.credit }}
      </div>
      <div class="vx-col md:w-1/3 text-center">{{ credit.payment_method }}</div>
      <div class="vx-col md:w-1/3 text-center">
        {{ GetRelativeTime(credit.created) }}
      </div>
      <div class="vx-col md:w-1/6 text-center">
        <div
          class="mx-auto"
          style="width: 16px !important; height: 16px; border-radius: 50%"
          :style="'background-color: ' + GetStatusColor(credit.status)"
        ></div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["data"],
  methods: {
    GetRelativeTime(value) {
      return moment(String(value)).format("DD-MM-YY - HH:mm");
    },
    GetStatusColor(status) {
      if (status == "1") return "#28C76F";
      if (status == "2") return "#EA5455";
      return "#C89E82";
    },
  },
};
</script>

<style scoped>
.orderId {
  color: #117fed;
}
.vx-row {
  padding: 0px;
  margin: 0px;
}
.vx-col {
  padding: 0px;
  margin: 0px;
}
</style>
